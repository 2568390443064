<template>
  <div class="">
    <div class="form-row">
      <sgv-input-select
        label="บัญชี"
        placeholder="เลือกรายการ"
        class="col-6"
        :options="options"
        select="id"
        v-model="formData.docConfigId"
        :validations="[
          {text: 'required!', value: $v.formData.docConfigId.$dirty && $v.formData.docConfigId.$error}
        ]">
        <template slot-scope="option">
          {{option.name}}
        </template>
      </sgv-input-select>
    </div>

    <div class="form-row">
      <sgv-input-select
        label="ประเภท"
        placeholder="เลือกรายการ"
        class="col-6"
        :options="topics"
        select="value"
        v-model="formData.withholdingTopic"
        :validations="[
          {text: 'required!', value: $v.formData.withholdingTopic.$dirty && $v.formData.withholdingTopic.$error}
        ]">
        <template slot-scope="option">
          {{option.text}}
        </template>
      </sgv-input-select>

      <sgv-input-text
        class="col-6"
        label="รายละเอียด"
        v-model="formData.withholdingName"
        :validations="[
          {text: 'required!', value: $v.formData.withholdingName.$dirty && $v.formData.withholdingName.$error}
        ]">
      </sgv-input-text>
    </div>

    <div class="form-row">
      <sgv-input-select
        label="ผู้จ่ายเงิน"
        placeholder="เลือกรายการ"
        class="col-6"
        :options="payers"
        select="value"
        v-model="formData.withholdingType"
        :validations="[
          {text: 'required!', value: $v.formData.withholdingType.$dirty && $v.formData.withholdingType.$error}
        ]">
        <template slot-scope="option">
          {{option.text}}
        </template>
      </sgv-input-select>

      <sgv-input-text
        v-if="formData.withholdingType === 4"
        class="col-6"
        label="หมายเหตุ"
        v-model="formData.withholdingRemark">
      </sgv-input-text>
    </div>

    <div class="form-row">
      <sgv-input-currency
        class="col-6 col-sm-4"
        label="มูลค่าบริการ"
        v-model="formData.withholdingPrice"
        :precision="2"
        :validations="[
          {text: 'จำนวนมากกว่า 0', value: $v.formData.withholdingPrice.$dirty && !$v.formData.withholdingPrice.minValue}
        ]">
      </sgv-input-currency>

      <sgv-input-number
        class="col-6 col-sm-4"
        label="อัตราภาษี (%)"
        v-model="formData.withholdingRate"
        :precision="2"
        :validations="[
          {text: 'จำนวนมากกว่า 0', value: $v.formData.withholdingRate.$dirty && !$v.formData.withholdingRate.minValue}
        ]">
      </sgv-input-number>

      <sgv-input-currency
        class="col-6 col-sm-4"
        label="ราคา"
        v-model="formData.price"
        :precision="2"
        :validations="[
          {text: 'จำนวนมากกว่า 0', value: $v.formData.price.$dirty && !$v.formData.price.minValue}
        ]">
      </sgv-input-currency>
    </div>

    <button
      class="btn btn-success"
      @click="createOrder">
      เพิ่ม
    </button>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { round } from 'lodash'
import {
  LIST_ORDER,
  LIST_ORDER_CONFIG,
  CREATE_ORDER_WITHHOLDING_PRICE
} from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    contactId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      formType: 'withholdingPrice',
      formData: {
        orderId: null,
        docConfigId: null,
        withholdingType: 1,
        withholdingTopic: 5,
        withholdingName: '',
        withholdingRemark: '',
        withholdingRate: 0,
        withholdingPrice: 0,
        price: 0
      },
      orders: [],
      configs: [],
      payers: [
        {text: 'หักภาษี ณ ที่จ่าย', value: 1},
        {text: 'ออกภาษีให้ครั้งเดียว', value: 2},
        {text: 'ออกให้ตลอดไป', value: 3},
        {text: 'อื่นๆ', value: 4},
      ],
      topics: [
        {text: '1.เงินเดือน ค่าจ้าง โบนัส ม.40(1)', value: 1},
        {text: '2.ค่าธรรมเนียม ค่านายหน้า ม.40(2)', value: 2},
        {text: '3.ค่าแห่งลิขสิทธิ์ ม.40(3)', value: 3},
        {text: '5.มาตรา 3เตรส (ชิงโชค)', value: 5},
        {text: '6.อื่นๆ', value: 6},
      ]
    }
  },
  validations () {
    return {
      formData: {
        docConfigId: { required },
        withholdingType: { required },
        withholdingTopic: { required },
        withholdingName: { required },
        withholdingRate: { minValue: this.minValue },
        withholdingPrice: { minValue: this.minValue },
        price: { minValue: this.minValue },
      }
    }
  },
  computed: {
    methods () {
      return [{ text: 'เพิ่ม', value: 'add' }]
    },
    options () {
      return this.configs.filter(v => v.type === this.formType)
    }
  },
  apollo: {
    orders: {
      query () {
        return LIST_ORDER(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          docId: this.docId
        }
      },
      fetchPolicy: 'network-only',
      skip () {
        return !(this.docId > 0)
      }
    },
    configs: {
      query () {
        return LIST_ORDER_CONFIG(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          contactId: this.contactId
        }
      },
      fetchPolicy: 'network-only'
    },
  },
  methods: {
    minValue (value) {
      return value > 0
    },
    serializeCreateInput (input) {
      return {
        docConfigId: input.docConfigId,
        withholdingType: input.withholdingType,
        withholdingTopic: input.withholdingTopic,
        withholdingName: input.withholdingName,
        withholdingRate: input.withholdingRate,
        withholdingPrice: input.withholdingPrice,
        price: input.price,
      }
    },
    createOrder () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = this.serializeCreateInput(this.formData)

      this.$apollo.mutate({
        mutation: CREATE_ORDER_WITHHOLDING_PRICE(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          input
        }
      })
      .then(() => {
        this.setDefault()
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    setDefault () {
      this.formData.orderId = null
      this.formData.docConfigId = null
      this.$v.formData.$reset()
      const config = this.configs.find(v => v.type === this.formType && v.isDefault)
      if (config) this.formData.docConfigId = config.id
    },
    setWithholdingRate () {
      const docConfig = this.configs.find(v => v.id === this.formData.docConfigId)
      const withholdingPrice = docConfig?.options?.withholdingPrice
      if (!withholdingPrice) {
        this.formData.withholdingRate = 0
      } else if (withholdingPrice.indexOf('%') !== -1) {
        const withholdingRate = withholdingPrice.replace('%', '')
        this.formData.withholdingRate = +withholdingRate
      }
    },
    setPrice () {
      this.formData.price = round(this.formData.withholdingPrice * this.formData.withholdingRate / 100, 2)
    },
  },
  watch: {
    'formData.docConfigId': {
      handler (v) {
        this.setWithholdingRate()
        if (!v) {
          this.$v.formData.$reset()
        }
      }
    },
    'formData.withholdingRate': {
      handler (v) {
        if (v && this.formData.withholdingPrice) {
          this.setPrice()
        }
      }
    },
    'formData.withholdingPrice': {
      handler (v) {
        if (v && this.formData.withholdingRate) {
          this.setPrice()
        }
      }
    },
    orders (arr) {
      this.formData.withholdingPrice = arr
      .filter(v => ['item', 'service', 'expense', 'discountPrice'].includes(v.type))
      .reduce((t,v) => t + Number(v.totalPrice), 0)
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.setDefault()
    })
  }
}
</script>

<style lang="css" scoped>
</style>
