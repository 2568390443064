<template>
  <sgv-table
    :items="ordersMap"
    :headers="headers"
    :filter.sync="filter"
    :options.sync="options">

    <template slot-scope="{item}">
      <tr @dblclick="toggleRow(item.id)" class="pointer">
        <td>
          <template v-if="selectedRows.includes(item.id)">
            <span
              v-for="ledger in item.ledgers"
              :key="ledger.accountId">
              <small :class="{
                'text-success': ledger.approvedAt,
                'text-warning': !ledger.approvedAt
                }"
                class="text-nowrap">
                {{ledger.account.code}}: {{ledger.account.name}}
              </small>
              <br>
            </span>
          </template>
          {{item.id}}.
          <span style="white-space: pre-line;">{{item.name}}</span><br>

          <router-link
            v-for="child in item.docs"
            :key="child.id"
            :to="toDoc(child)"
            class="text-decoration-none text-info mr-1">
            <small>{{child.code}}</small>
          </router-link>

          <div v-if="item.payments">
            <router-link
              v-for="child in item.payments"
              :key="child.id"
              :to="toDoc(child)"
              class="text-decoration-none text-success mr-1">
              <small>{{child.code}}</small>
            </router-link>
          </div>

          <div v-if="item.badDebts">
            <router-link
              v-for="child in item.badDebts"
              :key="child.id"
              :to="toDoc(child)"
              class="text-decoration-none text-danger mr-1">
              <small>{{child.code}}</small>
            </router-link>
          </div>

          <div v-if="item.externalDoc">
            <router-link
              v-if="item.externalDoc"
              class="text-decoration-none text-info mr-1"
              :to="toDoc(item.externalDoc)">
              <small>{{item.externalDoc.code}}</small>
            </router-link>
          </div>

          <small v-if="withholdingType(item.withholdingType)">
            <div>
              <div class="text-info">
                {{item.withholdingTopic}}.
                {{item.withholdingName}}
                ({{item.withholdingRate}}%)
                ({{item.withholdingPrice}})
              </div>
              <div class="text-info">
                {{withholdingType.text}}
              </div>
              <div v-if="item.withholdingRemark" class="text-danger">
                หมายเหตุ: {{item.withholdingRemark}}
              </div>
            </div>
          </small>

          <template v-if="selectedRows.includes(item.id)">
            <span
              v-for="stock in item.stocks"
              :key="stock.id"
              class="text-primary text-nowrap">
              <br>
              <small>
                <em>{{ stock.inventory.code }} ({{stock.inventory.name}})</em>
              </small>
            </span>
          </template>
        </td>

        <td>
          <template v-if="selectedRows.includes(item.id)">
            <span v-for="ledger in item.ledgers" :key="ledger.id">
              <br>
            </span>
          </template>
          <span v-if="item.qty !== ''">{{item.qty | comma}}</span>
          <span v-else>-</span>
          <br>
          <template v-if="selectedRows.includes(item.id)">
            <small v-for="stock in item.stocks" :key="stock.id">
              <br>
              <span :class="{
                'text-success': stock.approvedAt,
                'text-warning': !stock.approvedAt
                }"
                class="pointer">
                TAG: {{stock.id}}
              </span>
              (
                <span
                  :class="{
                    'text-success': stock.qty > 0,
                    'text-danger': stock.qty < 0
                    }">
                  {{stock.qty | comma}}
                </span>
              )
              <span>
                {{stock.inventory.unit.name}}
              </span>
              <span class="text-secondary">
                ({{stock.parentId}})
              </span>
            </small>
          </template>
        </td>

        <td>
          <template v-if="selectedRows.includes(item.id)">
            <span v-for="ledger in item.ledgers" :key="ledger.id">
              <br>
            </span>
          </template>
          <span v-if="item.price !== ''">{{item.price | comma}}</span>
          <span v-else>-</span>
        </td>

        <td>
          <template v-if="selectedRows.includes(item.id)">
            <span v-for="ledger in item.ledgers" :key="ledger.id">
              <small :class="{'text-success': ledger.amount > 0, 'text-danger': ledger.amount < 0}">
                {{Math.abs(ledger.amount) | comma}}
              </small>
              <br>
            </span>
          </template>
          <span :class="{'text-danger': item.totalPrice < 0}">
            {{item.totalPrice | comma}}
          </span>
        </td>
      </tr>
    </template>
  </sgv-table>
</template>

<script>
import { round } from 'lodash'
import {
  LIST_ORDER,
  WATCH_ORDER_REFETCH
} from './graph'

export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docId: {
      type: Number,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    configs: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['name', 'qty', 'unit', 'price', 'totalPrice'],
        column: null,
        search: null,
        toolbar: null,
      },
      orders: [],
      selectedRows: [],
      withholdingTypes: [
        { text: 'หักภาษี ณ ที่จ่าย', value: 1 },
        { text: 'ออกภาษีให้ครั้งเดียว', value: 2 },
        { text: 'ออกให้ตลอดไป', value: 3 },
        { text: 'อื่นๆ', value: 4 },
      ]
    }
  },
  apollo: {
    orders: {
      query () {
        return LIST_ORDER(this.templateType)
      },
      variables() {
        return {
          docType: this.docType,
          docId: this.docId
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only',
      skip () {
        return !(this.docId > 0)
      },
    },
    $subscribe: {
      orderRefetch: {
        query () {
          return WATCH_ORDER_REFETCH(this.templateType)
        },
        variables () {
          return {
            docType: this.docType,
            docId: this.docId
          }
        },
        result () {
          this.$apollo.queries.orders.refetch()
        },
      }
    },
  },
  computed: {
    ordersMap () {
      const items = this.orders
      .filter(v => ['item'].includes(v.type))
      .reduce((t,v) => {
        const obj = {
          name: v.name,
          qty: +v.qty,
          unit: v.unit.name,
          price: v.price,
          totalPrice: +v.totalPrice,
          docs: [v.doc],
          children: v.children
        }
        if (t.length === 0) {
          t.push(obj)
        } else {
          const foundIndex = t.findIndex(x => x.name === obj.name && x.price === obj.price)
          if (foundIndex !== -1) {
            t[foundIndex].qty += obj.qty
            t[foundIndex].totalPrice += obj.totalPrice
            t[foundIndex].children = [...t[foundIndex].children, ...v.children]

            const found = t[foundIndex].docs.find(x => x.id === v.doc.id)
            if (!found) t[foundIndex].docs.push(v.doc)
          } else {
            t.push(obj)
          }
        }
        return t
      }, [])
      .map(v => {
        const ledgers = v.children
        .flatMap(v => v.ledgers)
        .reduce((t,ledger) => {
          const found = t.find(x => x.accountId === ledger.accountId)
          if (!found) {
            t.push({
              accountId: ledger.accountId,
              account: ledger.account,
              amount: +ledger.amount,
              approvedAt: ledger.approvedAt,
              approvedBy: ledger.approvedBy,
            })
          } else {
            found.amount += +ledger.amount
          }

          return t
        }, [])

        return {
          name: v.name,
          qty: v.qty,
          price: v.price,
          totalPrice: v.totalPrice,
          docs: v.docs,
          children: v.children,
          ledgers,
          stocks: v.children.flatMap(v => v.stocks),
        }
      })

      const services = this.orders
      .filter(v => ['service'].includes(v.type))
      .reduce((t,v) => {
        const obj = {
          name: v.name,
          qty: +v.qty,
          unit: v.unit.name,
          totalPrice: +v.totalPrice,
          docs: [v.doc],
          children: v.children
        }
        if (t.length === 0) {
          t.push(obj)
        } else {
          const foundIndex = t.findIndex(x => x.name === obj.name)
          if (foundIndex !== -1) {
            t[foundIndex].qty += obj.qty
            t[foundIndex].totalPrice += obj.totalPrice
            t[foundIndex].children = [...t[foundIndex].children, ...v.children]

            const found = t[foundIndex].docs.find(x => x.id === v.doc.id)
            if (!found) t[foundIndex].docs.push(v.doc)
          } else {
            t.push(obj)
          }
        }
        return t
      }, [])
      .map(v => {
        return {
          name: v.name,
          qty: v.qty,
          price: '',
          totalPrice: v.totalPrice,
          docs: v.docs,
          children: v.children,
          ledgers: v.children.flatMap(v => v.ledgers),
          stocks: v.children.flatMap(v => v.stocks),
        }
      })

      const expenses = this.orders
      .filter(v => ['expense'].includes(v.type))
      .reduce((t,v) => {
        const obj = {
          name: v.name,
          qty: +v.qty,
          unit: v.unit.name,
          totalPrice: +v.totalPrice,
          docs: [v.doc],
          children: v.children
        }
        if (t.length === 0) {
          t.push(obj)
        } else {
          const foundIndex = t.findIndex(x => x.name === obj.name)
          if (foundIndex !== -1) {
            t[foundIndex].qty += obj.qty
            t[foundIndex].totalPrice += obj.totalPrice
            t[foundIndex].children = [...t[foundIndex].children, ...v.children]

            const found = t[foundIndex].docs.find(x => x.id === v.doc.id)
            if (!found) t[foundIndex].docs.push(v.doc)
          } else {
            t.push(obj)
          }
        }
        return t
      }, [])
      .map(v => {
        return {
          name: v.name,
          qty: v.qty,
          price: '',
          totalPrice: v.totalPrice,
          docs: v.docs,
          children: v.children,
          ledgers: v.children.flatMap(v => v.ledgers),
          stocks: v.children.flatMap(v => v.stocks),
        }
      })

      const discountPrices = this.orders
      .filter(v => ['discountPrice'].includes(v.type))
      .reduce((t,v) => {
        const obj = {
          name: v.name,
          qty: +v.qty,
          unit: v.unit.name,
          totalPrice: +v.totalPrice,
          docs: [v.doc],
          children: v.children
        }
        if (t.length === 0) {
          t.push(obj)
        } else {
          const foundIndex = t.findIndex(x => x.name === obj.name)
          if (foundIndex !== -1) {
            t[foundIndex].qty += obj.qty
            t[foundIndex].totalPrice += obj.totalPrice
            t[foundIndex].children = [...t[foundIndex].children, ...v.children]

            const found = t[foundIndex].docs.find(x => x.id === v.doc.id)
            if (!found) t[foundIndex].docs.push(v.doc)
          } else {
            t.push(obj)
          }
        }
        return t
      }, [])
      .map(v => {
        return {
          name: v.name,
          qty: v.qty,
          price: '',
          totalPrice: v.totalPrice,
          docs: v.docs,
          children: v.children,
          ledgers: v.children.flatMap(v => v.ledgers),
          stocks: v.children.flatMap(v => v.stocks),
        }
      })

      const vatPrices = this.orders
      .filter(v => ['vatPrice'].includes(v.type))
      .reduce((t,v) => {
        const obj = {
          name: v.name,
          unit: v.unit.name,
          totalPrice: +v.totalPrice,
          docs: [v.doc],
          children: v.children
        }
        if (t.length === 0) {
          t.push(obj)
        } else {
          const foundIndex = t.findIndex(x => x.name === obj.name)
          if (foundIndex !== -1) {
            t[foundIndex].totalPrice += obj.totalPrice
            t[foundIndex].children = [...t[foundIndex].children, ...v.children]

            const found = t[foundIndex].docs.find(x => x.id === v.doc.id)
            if (!found) t[foundIndex].docs.push(v.doc)
          } else {
            t.push(obj)
          }
        }
        return t
      }, [])
      .map(v => {
        return {
          name: v.name,
          qty: '',
          price: '',
          totalPrice: v.totalPrice,
          docs: v.docs,
          children: v.children,
          ledgers: v.children.flatMap(v => v.ledgers),
          stocks: v.children.flatMap(v => v.stocks),
        }
      })

      const netPrices = this.orders
      .filter(v => ['netPrice'].includes(v.type))
      .reduce((t,v) => {
        const payments = v.children
        .flatMap(child => child.children)
        .filter(child => child.type === 'invoice')
        .reduce((t,child) => {
          const found = t.find(x => x.id === child.doc.id)
          if (!found) t.push(child.doc)
          return t
        }, [])

        const badDebts = v.children
        .flatMap(child => child.children)
        .filter(child => child.type === 'badDebt')
        .reduce((t,child) => {
          const found = t.find(x => x.id === child.doc.id)
          if (!found) t.push(child.doc)
          return t
        }, [])

        const obj = {
          name: v.name,
          unit: v.unit.name,
          totalPrice: +v.totalPrice,
          docs: [v.doc],
          children: v.children,
          payments,
          badDebts
        }
        if (t.length === 0) {
          t.push(obj)
        } else {
          const foundIndex = t.findIndex(x => x.name === obj.name)
          if (foundIndex !== -1) {
            t[foundIndex].totalPrice += obj.totalPrice
            t[foundIndex].children = [...t[foundIndex].children, ...v.children]
            t[foundIndex].payments = payments

            const found = t[foundIndex].docs.find(x => x.id === v.doc.id)

            if (!found) t[foundIndex].docs.push(v.doc)
          } else {
            t.push(obj)
          }
        }
        return t
      }, [])
      .map(v => {
        return {
          name: v.name,
          qty: '',
          price: '',
          totalPrice: v.totalPrice,
          docs: v.docs,
          payments: v.payments,
          badDebts: v.badDebts,
          children: v.children,
          ledgers: v.children.flatMap(v => v.ledgers),
          stocks: v.children.flatMap(v => v.stocks),
        }
      })

      const deposits = this.orders
      .filter(v => ['deposit'].includes(v.type))
      .reduce((t,v) => {
        const obj = {
          name: v.name,
          qty: +v.qty,
          unit: v.unit.name,
          totalPrice: +v.totalPrice,
          docs: [v.doc],
          children: v.children
        }
        if (t.length === 0) {
          t.push(obj)
        } else {
          const foundIndex = t.findIndex(x => x.name === obj.name)
          if (foundIndex !== -1) {
            t[foundIndex].qty += obj.qty
            t[foundIndex].totalPrice += obj.totalPrice
            t[foundIndex].children = [...t[foundIndex].children, ...v.children]

            const found = t[foundIndex].docs.find(x => x.id === v.doc.id)
            if (!found) t[foundIndex].docs.push(v.doc)
          } else {
            t.push(obj)
          }
        }
        return t
      }, [])
      .map(v => {
        return {
          name: v.name,
          qty: v.qty,
          price: '',
          totalPrice: v.totalPrice,
          docs: v.docs,
          children: v.children,
          ledgers: v.children.flatMap(v => v.ledgers),
          stocks: v.children.flatMap(v => v.stocks),
        }
      })

      const withholdings = this.orders
      .filter(o => o.type === 'withholdingPrice')
      .map(v => {
        return {
          name: v.name,
          qty: '',
          price: '',
          totalPrice: v.totalPrice,
          docs: v.docs,
          children: v.children,
          ledgers: v.ledgers,
          stocks: v.stocks,
          withholdingType: v.withholdingType,
          withholdingTopic: v.withholdingTopic,
          withholdingName: v.withholdingName,
          withholdingRemark: v.withholdingRemark,
          withholdingRate: v.withholdingRate,
          withholdingPrice: v.withholdingPrice,
          externalDocId: v.externalDocId,
          externalDoc: v.externalDoc
        }
      })

      return [
        ...items,
        ...services,
        ...expenses,
        ...discountPrices,
        ...deposits,
        ...vatPrices,
        ...withholdings,
        ...netPrices
      ].map((v, i) => {
        v.id = i + 1
        return v
      })
    },
    headers () {
      return [
        {text: 'รายละเอียด', value: 'name'},
        {text: 'จำนวน', value: 'qty'},
        {text: 'ราคา/หน่วย', value: 'price'},
        {text: `ราคา (${this.ledgersSum})`, value: 'totalPrice'}
      ]
    },
    isEditable () {
      return this.formData.approvedAt && !this.formData.closedAt
    },
    ledgersSum () {
      let total = 0
      this.orders.forEach(order => {
        if (order.type === 'withholdingPrice') {
          total += order.ledgers.reduce((sub, ledger) => sub += +ledger.amount, 0)
        }
        order.children.forEach(child => {
          total += child.ledgers.reduce((sub, ledger) => sub += +ledger.amount, 0)
        })
      })
      return round(total, 2)
    },
  },
  methods: {
    withholdingType(type) {
      return this.withholdingTypes.find(v => v.value === type)
    },
    toggleRow (id) {
      const found = this.selectedRows.findIndex(v => v === id)
      if (found === -1) this.selectedRows.push(id)
      else this.selectedRows.splice(found, 1)
    },
    toDoc (doc) {
      return {
        name: `Doc${this.$form.capitalize(doc.type)}Detail`,
        params: {docId: doc.id}
      }
    },
  }
}
</script>

<style lang="css">
</style>
